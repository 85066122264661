import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContestantService {

  
  league_info:any = {
    no_teams: 10,
    user_draft_order:3
  }

  draft_order:any = [];

  players:any = [];

  colin:any = {
    name:'Colin',
    desc:'Wants nothing to do with \'backwards-hat kind-a guys\'',
    not_drafting:['Baker Mayfield CLE','Sam Darnold NYJ'],
    drafting:['Russell Wilson SEA']
  }
  dk:any = {
    name:'DK',
    desc:'Is not going for a running back until the last rounds',
  }
  jj:any = {
    name:'JJ',
    desc:'Will wait to take a QB until the late rounds'
  }
  bot:any = {
    name:'Bot',
    desc:'Always goes for B.P.A.'
  }

  constructor() { }

  
}
