import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../_services/config.service';
import {LeagueService} from '../_services/league.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private cs:ConfigService, private ls:LeagueService) { }

  order:number = this.getOrder();

  ngOnInit() {
  }

  getNoContestants(){
    return this.cs.getNoContestants();
  }

  getOrder(){
    return this.cs.getOrder();
  }

  shuffle(){
    this.ls.shuff();
  }
  getPointer(){
    return this.cs.getPickPointer();
  }
  getPossible(){
    return this.cs.getPossible()
  }
  updateOrder(event:any){
    this.cs.resetPlayerOrder(event);
  }
  openSettings(){
    this.cs.openFronSettings();
  }

}
