import { Component, OnInit } from '@angular/core';

import {ConfigService} from '../_services/config.service';
import {GraphService} from '../_services/graph.service';
import {LeagueService} from '../_services/league.service';

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.css']
})
export class PlayersComponent implements OnInit {

  constructor(private cs:ConfigService,private gs:GraphService, private ls:LeagueService) { }

  np:string;

  ngOnInit() {
    //this.nP();
  }

  highlightPoint(key:string,pos:string,picked:number){
    let si = this.gs.getSeriesIndex(key,pos);
    this.gs.highlightPoint(si[0],si[1],picked);
    if(picked){
      this.gs.removePoint(si[0],si[1]);
    }
    

  }

  getScoring(){
    return this.cs.getYS();
  }
  getPlayers(){
    return this.cs.getPlayers();
  }

  getBye(team:string){
    return this.cs.getBye(team);
  }

  draftPlayer(player:any){
    this.cs.draftPlayer(player);
    //this.ls.autoDraft();
    let si = this.gs.getSeriesIndex(player.key,player.POS);
    this.gs.removePoint(si[0],si[1]);
  }

  removePlayer(player:any){
    this.cs.removePlayer(player);
    let si = this.gs.getSeriesIndex(player.key,player.POS);
    this.gs.removePoint(si[0],si[1]);
  }

  nP(pos:string){
    return this.cs.noPicked(pos);
  }

  isPick(){
    return this.cs.isPick();
  }
  isInRange(i:any){
    //find how many picks until next pick (x)
    //find xth player not taken
    let picks = this.cs.getPicksUntil();
    let x = 0;
    let end_player_index = 0;
    for(let p of this.cs.getPlayers()){
      if(x == picks){
        end_player_index = this.cs.getPlayers().indexOf(p);
        break;
      }
      if(p.picked == 0){
        x += 1;
      }
    }
    if(i <= end_player_index){
      return false;
    }
    else{
      return true;
    }
  }

  flipStarred(p){
    p.starred = !p.starred;
  }
  flipFlagged(p){
    p.flagged = !p.flagged;
  }

}
