import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../_services/config.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  constructor(private cs:ConfigService) { }

  myTeam:any = [];


  ngOnInit() {
    this.myTeam = this.cs.getMyTeam();
  }


  getBye(team:string){
    return this.cs.getBye(team);
  }


}
