import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { ContestantService} from './contestant.service';

@Injectable({
  providedIn: 'root'
})
export class LeagueService {



  league_info:any = {
    no_teams: this.cs.getNoContestants(),
    user_draft_order:this.cs.getOrder()
  }

  draft_order:any = [];

  players:any = [];

  

  pos_short = ['QB','RB','WR','FLX','SFLX','TE','K','DST'];

  constructor(private cs:ConfigService, private cose:ContestantService) { 
    this.generateDraftOrder();
  }

  generateLeague(){
    //this.generatePlayers();
  }

  generatePlayers(){
    let players = [];
    for(let i = 0; i < this.league_info.no_teams;i++){

      players.push({
        name:'Bot' + (i+1),
        desc:'Always goes for B.P.A',
        roster:this.generateRoster()
      });
    };
    players[this.league_info.user_draft_order - 1] = {
      name:'My Team',
      desc:'Your Team',
      roster:this.cs.getMyTeam()
    }
    this.players = players;
    console.log(players);
  }

  getLeauge(){
    return this.players;
  }
  getLeaugeInfo(){
    return this.league_info;
  }

  generateRoster(){
    const times = x => f => {
      if (x > 0) {
        f()
        times (x - 1) (f)
      }
    }
    let myTeam = [];
    let positions = this.cs.getPositions();
    for(var i in positions){
      if(i == '7'){
        times(positions[i])(() => myTeam.push({pos:'Bench',player:null}));
      }
      else{
        times(positions[i])(() => myTeam.push({pos:this.pos_short[i],player:null}));
      }
      
    }
    return myTeam;
  }

  shuff(){
     this.shuffle(this.players);
     this.cs.setOrder(this.getUserDraftOrder());
     this.generateDraftOrder()
     this.cs.setDraftOrder(this.draft_order);
  }

  //knuth-shuffle
  shuffle(array:any) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }
  getUserDraftOrder(){
    return this.players.findIndex(x => x.name == 'My Team') + 1;
  }

  startDraft(){
    console.log("draft");
    this.generateDraftOrder();
    this.autoDraft();
  }

  autoDraft(){
    let picker = this.draft_order[0];
    while(picker != 'My Team' && typeof picker != 'undefined'){
      picker = this.draft_order.shift();
      console.log(picker);
    }
  }

  

  generateDraftOrder(){
    this.draft_order = [];
    let no_rounds = this.getRounds();
    let odd_rounds = no_rounds % 2;

    if(odd_rounds){
      no_rounds -= 1;
    }

    for(let i = 0; i < (no_rounds/2); i++){
      for(let p of this.players){
        this.draft_order.push(p.name);
      }
      for(let p_ = this.players.length-1; p_ >= 0; p_--){
        this.draft_order.push(this.players[p_].name);
      }
    }
    if(odd_rounds){
      for(let p of this.players){
        this.draft_order.push(p.name);
      }
    }
  }

  getRounds(){
    let pos = this.cs.getPositions();
    let rounds = 0;
    for(let p of pos){
      rounds += p;
    }
    return rounds;
  }
}
