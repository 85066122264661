import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../_services/config.service';
import {LeagueService} from '../_services/league.service';
import {GraphService} from '../_services/graph.service';


@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.css']
})
export class PositionsComponent implements OnInit {

  constructor(private cs:ConfigService,private gs:GraphService,private ls:LeagueService) { }

  ngOnInit() {

  }

  getQBs(){
    return this.cs.getQBs();
  }

  getRBs(){
    return this.cs.getRBs();
  }
  getWRs(){
    return this.cs.getWRs();
  }
  getTEs(){
    return this.cs.getTEs();
  }
  getKs(){
    return this.cs.getKs();
  }
  getDSTs(){
    return this.cs.getDSTs();
  }

  getScoring(){
    return this.cs.getYS();
  }

  getBye(team:string){
    return this.cs.getBye(team);
  }
  
  draftPlayer(player:any){
    this.cs.draftPlayer(player);
    
    let si = this.gs.getSeriesIndex(player.key,player.POS);
    this.gs.removePoint(si[0],si[1]);
    //this.ls.autoDraft();
  }

  removePlayer(player:any){
    this.cs.removePlayer(player);
    let si = this.gs.getSeriesIndex(player.key,player.POS);
    this.gs.removePoint(si[0],si[1]);
  }

  nP(pos:string){
    return this.cs.noPicked(pos);
  }

  redoPlayer(player:any){
    this.cs.unremovePlayer(player);
    let si = this.gs.getSeriesIndex(player.key,player.POS);
    this.gs.resetPoint(si[0],si[1]);
  }

  highlightPoint(key:string,pos:string,picked:number){
    let si = this.gs.getSeriesIndex(key,pos);
    this.gs.highlightPoint(si[0],si[1],picked);
    
  }

  dstName(name:string){
    let index = name.lastIndexOf(" ");
    //console.log(name.substring(0, index-3))
    return name.substring(0, index-3);
    
  }
  flipStarred(p){
    p.starred = !p.starred;
  }
  flipFlagged(p){
    p.flagged = !p.flagged;
  }
}
