import { Component, OnInit,ViewChild } from '@angular/core';
import {ConfigService} from '../_services/config.service';
import {GraphService} from '../_services/graph.service';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { BaseChartDirective } from 'ng2-charts';


@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css']
})


export class GraphComponent implements OnInit {
  @ViewChild(BaseChartDirective,{static: false}) 
  chart: BaseChartDirective;


  constructor(private cs:ConfigService, private gs:GraphService) {
    this.setChart();
  }

  public scatterChartOptions: ChartOptions = this.gs.scatterChartOptions;
  public scatterChartData: ChartDataSets[] = this.gs.scatterChartData;
  public scatterChartType: ChartType = 'scatter';

  public options: {
    
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }


  ngOnInit() {
    
    //Wait for chart to be defined
    (async() => {
      while(typeof this.chart == 'undefined') // define the condition as you like
          await new Promise(resolve => setTimeout(resolve, 1000));
      this.setChart();
    })();

    
    
  }  

  getPlayers(){
    return this.cs.getPlayers();
  }

  draftPlayer(player:any){
    this.cs.draftPlayer(player);
  }

  setChart(){
    this.gs.setChart(this.chart);
  }

  changeRanking(from:string){
    this.gs.updateRankings(from);
  }

  changeProjections(proj:string){
    this.gs.updateProjections(proj);
  }

  

  

}
