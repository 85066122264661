import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../_services/config.service';

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.css']
})
export class FrontpageComponent implements OnInit {

  constructor(private cs:ConfigService) { }

  possibleForPosition = {
    'QB':4,
    'RB':6,
    'WR':6,
    'TE':4,
    'FLX':10,
    'SFLX':10,
    'K':4,
    'DST':4,
    'BENCH':10
  }

  basePositions =['QB','RB','WR','TE','DST','K']
  order:number = this.cs.getOrder();
  contestants:number = this.cs.getNoContestants();
  positions = Object.keys(this.possibleForPosition);
  possible_contestants = [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];

  ngOnInit() {
    
    
  }

  getFrontOpen(){
    return this.cs.getFrontOpen();
  }
  closeFront(){
    //console.log(Array.from(Array(this.possibleForPosition['qb']).keys()));
    this.cs.closeFrontSettings();
  }

  resetLeague(){
    this.cs.resetPlayers();
    this.cs.closeFrontSettings();
    this.cs.generateDraftOrder();
  }

  getPossibleForPositions(pos:string){
    return Array.from(Array(this.possibleForPosition[pos]).keys());
  }

  updateNumbers(pos:string,no:any){
    
    this.cs.setPosNumber(pos,no);
    this.cs.initMyTeam();
  }

  getPosNumber(pos:string){
    return this.cs.getPosNumber(pos);
  }
  getOrder(){
    return this.cs.getOrder();
  }

  getPointer(){
    return this.cs.getPickPointer();
  }
  getPossible(){
    return this.cs.getPossible()
  }
  updateOrder(event:any){
    this.cs.resetDraftOrder(event);
  }

  isInFLX(flx_type,pos){
    return this.cs.isInFLX(flx_type,pos);
  }
  setFLX(flx_type,is_add,pos){
    this.cs.setFLX(flx_type,is_add,pos);
  }
  updateContestants(event){
    this.cs.setNumberOfContestants(event);
    this.order = this.cs.getOrder();
  }

  

}
