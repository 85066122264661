import { Injectable,ViewChild } from '@angular/core';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { BaseChartDirective } from 'ng2-charts';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class GraphService {
  chart: BaseChartDirective;

  players:any;
  np:string;

  qbs:Label[] = [];
  wrs:Label[] = [];
  rbs:Label[] = [];
  tes:Label[] = [];
  ks:Label[] = [];
  dst:Label[] = [];

  qb_data:any = [];
  wr_data:any = [];
  rb_data:any = [];
  te_data:any = [];
  k_data:any = [];
  dst_data:any = [];

  radius = 5;

  qb_radius:any = [];
  wr_radius:any = [];
  rb_radius:any = [];
  te_radius:any = [];
  k_radius:any = [];
  dst_radius:any = [];

  x_selected:string = "avg";
  //pts, half_ppr, full_ppr
  y_selected:string = "half_ppr";

  selected_point:any = {
    set:0,
    index:0
  }

  all_labels:any = [this.qbs,this.rbs,this.wrs,this.tes,this.ks,this.dst];
  pos_short = ['QB','RB','WR','TE','K','DST'];

  // scatter
  public scatterChartOptions = this.setOptions();

  public scatterChartData: ChartDataSets[] = this.setChartData();
  public scatterChartType: ChartType = 'scatter';

  public options: {
    
  }

  constructor(private cs:ConfigService) { 
    this.cs.getJSON().subscribe(data => {

      this.players = data;
      
      this.setDataSeries(this.x_selected,this.y_selected);
    });
    
  }

  highlightPoint(set:any,index:any,picked:number){
    
    let s = this.selected_point.set;
    let i = this.selected_point.index;
    if(this.chart.chart.data.datasets[set]['pointRadius'][index] != 0){
      if(this.chart.chart.data.datasets[s]['pointRadius'][i] != 0){
        this.chart.chart.data.datasets[s]['pointRadius'][i] = 5;
      }
      this.chart.chart.data.datasets[set]['pointRadius'][index] = 15;
      this.chart.chart.update();
      this.selected_point.set = set;
      this.selected_point.index = index;
    }

  }

  removePoint(set:any,index:any){

    this.chart.chart.data.datasets[set]['pointRadius'][index] = 0;
    this.chart.chart.update();

  }

  resetPoint(set:any,index:any){
    this.chart.chart.data.datasets[set]['pointRadius'][index] = 45;
  }

  setChart(chart:any){
    this.chart = chart;
  }

  setOptions(){
    let op: ChartOptions = {
      responsive: true,
      tooltips: {
        callbacks:{
          label:(item,data) => {
            return this.all_labels[item.datasetIndex][item.index];
          }
        }
      },
      scales:{xAxes:[{ticks:{fontColor:"white"}}],
              yAxes:[{ticks:{fontColor:"white"}}]
      },
      legend: {
        labels: {
            fontColor: "white",
            fontSize: 12
        }
      }
      
    };
    return op;
  }

  

  getSeriesIndex(key:string,pos:string){
    let dset = this.pos_short.findIndex(x => x == pos);
    let index = this.all_labels[dset].findIndex(y => y == key);
    return [dset,index];
  }

 

  updateRankings(rank:string){
    let temp_p = [];
    let temp_set = [];

    for(let lindex in this.all_labels){
      temp_set = this.all_labels[lindex];
      for(let pl in this.all_labels[lindex]){
        temp_p = this.players.find(x => x.key == temp_set[pl]);
        this.chart.chart.data.datasets[lindex].data[pl]['x'] = temp_p[rank];
      }
    }
    this.cs.setXS(rank);

    this.chart.chart.update();
    
  }

  updateProjections(rank:string){
    let temp_p = [];
    let temp_set = [];

    for(let lindex in this.all_labels){
      temp_set = this.all_labels[lindex];
      for(let pl in this.all_labels[lindex]){
        temp_p = this.players.find(x => x.key == temp_set[pl]);
        this.chart.chart.data.datasets[lindex].data[pl]['y'] = temp_p[rank];
      }
    }
    
    this.cs.setYS(rank);
    this.chart.chart.update();
    
  }

  clearData(){
    this.qbs = [];
    this.qb_data = [];
    this.qb_radius = [];

    this.rbs = [];
    this.rb_data = [];
    this.rb_radius = [];

    this.wrs = [];
    this.wr_data = [];
    this.wr_radius = [];

    this.tes = [];
    this.te_data = [];
    this.te_radius = [];

    this.ks = [];
    this.k_data = [];
    this.k_radius = [];

    this.dst = [];
    this.dst_data = [];
    this.dst_radius = [];
  }


  setChartData(){
    let ch:ChartDataSets[] = [
      {
        data: this.qb_data,
        label: 'QB',
        pointRadius: this.qb_radius,
        pointBackgroundColor:"rgba(0, 180, 0, 1)",
        backgroundColor:"rgba(0, 180, 0, 1)",
        borderColor:"rgba(0, 180, 0, 1)",
        
      },
      {
        data: this.rb_data,
        label: 'RB',
        pointRadius: this.rb_radius,
        pointBackgroundColor:"rgba(255, 255, 0, 1)",
        backgroundColor:"rgba(255, 255, 0, 1)",
        borderColor:"rgba(255, 255, 0, 1)"
      },
      {
        data: this.wr_data,
        label: 'WR',
        pointRadius: this.wr_radius,
        pointBackgroundColor:"rgba(0, 0, 255, 1)",
        backgroundColor:"rgba(0, 0, 255, 1)",
        borderColor:"rgba(0, 0, 255, 1)"
      },
      {
        data: this.te_data,
        label: 'TE',
        pointRadius: this.te_radius,
        pointBackgroundColor:"rgba(0, 0, 0, 1)",
        backgroundColor:"rgba(0, 0, 0, 1)",
        borderColor:"rgba(0, 0, 0, 1)"
      },
      {
        data: this.k_data,
        label: 'K',
        pointRadius: this.k_radius,
        pointBackgroundColor:"rgba(0, 200, 255, 1)",
        backgroundColor:"rgba(0,200, 255, 1)",
        borderColor:"rgba(0,200, 255, 1)"
      },
      {
        data: this.dst_data,
        label: 'DST',
        pointRadius: this.dst_radius,
        pointBackgroundColor:"rgba(255, 0, 0, 1)",
        backgroundColor:"rgba(255, 0, 0, 1)",
        borderColor:"rgba(255, 0, 0, 1)"
      }
    ];

    return ch;

  }

  setDataSeries(x_col:string, y_col:string){
    for(let p of this.players){
      if(p.POS == "QB"){
        this.qbs.push(p.key);
        this.qb_data.push({x:p[x_col],y:p[y_col]});
        this.qb_radius.push(5);
      }
      else if(p.POS == "RB"){
        this.rbs.push(p.key);
        this.rb_data.push({x:p[x_col],y:p[y_col]});
        this.rb_radius.push(5);
      }
      else if(p.POS == "WR"){
        this.wrs.push(p.key);
        this.wr_data.push({x:p[x_col],y:p[y_col]});
        this.wr_radius.push(5);
      }
      else if(p.POS == "TE"){
        this.tes.push(p.key);
        this.te_data.push({x:p[x_col],y:p[y_col]});
        this.te_radius.push(5);
      }
      else if(p.POS == "K"){
        this.ks.push(p.key);
        this.k_data.push({x:p[x_col],y:p[y_col]});
        this.k_radius.push(5);
      }
      else if(p.POS == "DST"){
        this.dst.push(p.key);
        this.dst_data.push({x:p[x_col],y:p[y_col]});
        this.dst_radius.push(5);
      }
    }
    
  }

  
}
