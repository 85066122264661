import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs';
import {ContestantService} from './contestant.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  front_page_open:boolean = true;
  //snake or auction
  draft_type:string = 'snake';


  current_picker:any = '';

  pick_pointer:number = 0;
  draft_order:any = [];
  player_order:any = 4;
  no_contestants:any = 10;
  possible_order:any = [];


  players:any;
  qbs:any = [];
  rbs:any = [];
  wrs:any = [];
  tes:any = [];
  ks:any = [];
  dsts:any = [];

  no_picked:number = 0;

  qbs_picked:number = 0;
  rbs_picked:number = 0;
  wrs_picked:number = 0;
  tes_picked:number = 0;
  ks_picked:number = 0;
  dsts_picked:number = 0;

  qb_data:any = [];
  wr_data:any = [];
  rb_data:any = [];
  te_data:any = [];
  k_data:any = [];
  dst_data:any = [];
  
  no_qbs:number = 1;
  no_rbs:number = 2;
  no_wrs:number = 2;
  no_tes:number = 1;
  no_flex:number = 1;
  no_sflex:number = 1;
  no_ks:number = 1;
  no_dsts:number = 1;
  no_bench:number = 6;


  x_selected:string = "avg";
  //pts, half_ppr, full_ppr
  y_selected:string = "half_ppr";

  positions = [
    this.no_qbs,
    this.no_rbs,
    this.no_wrs,
    this.no_flex,
    this.no_sflex,
    this.no_tes,
    this.no_ks,
    this.no_dsts,
    this.no_bench
  ]

  roster_structure = {
    'QB':this.no_qbs,
    'RB':this.no_rbs,
    'WR':this.no_wrs,
    'FLX':this.no_flex,
    'SFLX':this.no_sflex,
    'TE':this.no_tes,
    'K':this.no_ks,
    'DST':this.no_dsts,
    'BENCH':this.no_bench
  }

  myTeam:any = [];

  pos_short = ['QB','RB','WR','TE','K','DST'];
  p_picked = [this.qbs_picked,this.rbs_picked,this.wrs_picked,this.tes_picked,this.ks_picked,this.dsts_picked];
  //TODO: Update
  byes:any = {
    'ARI':12,
    'ATL':6,
    'BAL':8,
    'BUF':7,
    'CAR':13,
    'CHI':10,
    'CIN':10,
    'CLE':13,
    'DAL':7,
    'DEN':11,
    'DET':9,
    'GB': 13,
    'HOU':10,
    'IND':14,
    'JAC':7,
    'KC':12,
    'LAC':7,
    'LAR':11,
    'LV':8,
    'MIA':14,
    'MIN':7,
    'NE':14,
    'NO':6,
    'NYG':10,
    'NYJ':6,
    'PHI':14,
    'PIT':7,
    'SEA':9,
    'SF':6,
    'TB':9,
    'TEN':13,
    'WAS':9
  }

  flx_positions = ['RB','WR']
  sflx_positions = ['QB','RB','WR','TE'];

  constructor(private http: HttpClient,private cose:ContestantService) {
    this.initPossible();
    this.generateDraftOrder();
    this.getJSON().subscribe(data => {
      function compare( a:any, b:any ) {
        if ( a['avg'] < b['avg'] ){
          return -1;
        }
        if ( a['avg'] > b['avg'] ){
          return 1;
        }
        return 0;
      }
      
      data = data.sort( compare );
      this.qbs = data.filter(x => x.POS == "QB");
      this.rbs = data.filter(x => x.POS == "RB");
      this.wrs = data.filter(x => x.POS == "WR");
      this.tes = data.filter(x => x.POS == "TE");
      this.ks = data.filter(x => x.POS == "K");
      this.dsts = data.filter(x => x.POS == "DST");

      this.players = data;
      for(let p of data){
        if(p.POS == "QB"){
          this.qb_data.push({x:p['avg'],y:p[this.y_selected]});
        }
        else if(p.POS == "RB"){
          this.rb_data.push({x:p.avg,y:p[this.y_selected]});
        }
        else if(p.POS == "WR"){
          this.wr_data.push({x:p.avg,y:p[this.y_selected]});
        }
        else if(p.POS == "TE"){
          this.te_data.push({x:p.avg,y:p[this.y_selected]});
        }
        else if(p.POS == "K"){
          this.k_data.push({x:p.avg,y:p[this.y_selected]});
        }
        else if(p.POS == "DST"){
          this.dst_data.push({x:p.avg,y:p[this.y_selected]});
        }
      }
    });

    this.initMyTeam();
  }

  public getJSON(): Observable<any> {
    return this.http.get("./assets/maindata.json");
  }

  getYS(){
    return this.y_selected;
  }

  setXS(x:string){
    this.x_selected = x;
  }

  setYS(y:string){
    this.y_selected = y;
  }
  isInFLX(flx_type,pos){
    if(flx_type == 'flx'){
      
      if(this.flx_positions.indexOf(pos) > -1){
        return true;
      }
      
    }
    else if(flx_type == 'sflx'){
      
      
      if(this.sflx_positions.indexOf(pos) > -1){
        return true;
      }
    }
    return false;
  }
  setFLX(flx_type,is_add,pos){
    if(flx_type == 'flx'){
      if(is_add){
        this.flx_positions.push(pos);
      }
      else{
        if(this.flx_positions.indexOf(pos) > -1){
          this.flx_positions.splice(this.flx_positions.indexOf(pos),1)
        }
      }
      console.log(this.flx_positions)
    }
    else if(flx_type == 'sflx'){
      if(is_add){
        this.sflx_positions.push(pos);
      }
      else{
        if(this.sflx_positions.indexOf(pos) > -1){
          this.sflx_positions.splice(this.sflx_positions.indexOf(pos),1)
        }
      }
    }
    console.log(this.sflx_positions)
  }

  draftPlayer(player:any){
    let spots = this.myTeam.filter(p => p.pos == player.POS);
    let added = false;
    for(let s of spots){
      if(s.player == null){
        player.picked = 1;
        //Check if player is in top 20
        let indx = this.players.findIndex(x => x.key == player.key);
        if(indx < (this.no_picked + 20)){
          this.no_picked += 1;
        }
        s.player = player;
        added = true;
        break;
      }
    }
    if(!added){
      let bench = this.myTeam.filter(p => p.pos == "BENCH");
      let flex = this.myTeam.filter(p => p.pos == "FLX");
      let sflx = this.myTeam.filter(p => p.pos == "SFLX");
      if(this.flx_positions.includes(player.POS)){
        for(let b of flex){
          if(b.player == null){
            player.picked = 1;
            //Check if player is in top 20
            let indx = this.players.findIndex(x => x.key == player.key);
            if(indx < (this.no_picked + 20)){
              this.no_picked += 1;
            }
            b.player = player;
            added = true;
            break;
          }
        }
      }
      if(!added && this.sflx_positions.includes(player.POS)){
        for(let b of sflx){
          if(b.player == null){
            player.picked = 1;
            //Check if player is in top 20
            let indx = this.players.findIndex(x => x.key == player.key);
            if(indx < (this.no_picked + 20)){
              this.no_picked += 1;
            }
            b.player = player;
            added = true;
            break;
          }
        }
      }
      
      if(!added){
        for(let b of bench){
          if(b.player == null){
            player.picked = 1;
            //Check if player is in top 20
            let indx = this.players.findIndex(x => x.key == player.key);
            if(indx < (this.no_picked + 20)){
              this.no_picked += 1;
            }
            b.player = player;
            break;
          }
        }
      }
      
    }
    if(player.picked){
      let pindex = this.pos_short.findIndex(x => x == player.POS);
      this.p_picked[pindex] += 1;
      this.pick_pointer += 1;
    }

  }

  removePlayer(player:any){
    player.picked = 1;
    this.pick_pointer += 1;
  }

  unremovePlayer(player:any){
    player.picked = 0;
    this.pick_pointer -= 1;

    for(let i = 0; i < this.myTeam.length; i++){
      if(this.myTeam[i].player != null){
        if(this.myTeam[i].player.key == player.key){
          this.myTeam[i].player = null;
        }
      }
    }
  }

  resetPlayers(){
    for(let t of this.myTeam){
      t.player = null;
    }
    for(let p of this.players){
      p.picked = 0;
    }
  }

  getBye(team:string){
    return this.byes[team];
  }

  getPlayers(){
    return this.players;
  }

  getMyTeam(){
    return this.myTeam;
  }

  getPositions(){
    return this.positions;
  }

  noPicked(pos:string){
    if(pos == 'all'){
      return (this.no_picked + 20).toString();
    }
    else{
      let pindex = this.pos_short.findIndex(x => x == pos);

      let no_disp = 15;
      if(pindex > 2){
        no_disp = 5;
      }
      return (this.p_picked[pindex]+no_disp).toString();
    }
    
    
  }
  
  setpositions(pos:string,no:number){
    if(pos == "QB"){
      this.no_qbs = no;
    }
    else if(pos == "WR"){
      this.no_wrs = no;
    }
    else if(pos == "RB"){
      this.no_rbs = no;
    }
    else if(pos == "TE"){
      this.no_tes = no;
    }
    else if(pos == "K"){
      this.no_ks = no;
    }
    else if(pos == "DST"){
      this.no_dsts = no;
    }
  }

  initMyTeam(){
    const times = x => f => {
      if (x > 0) {
        f()
        times (x - 1) (f)
      }
    }
    this.myTeam.length = 0;
    for(var i of Object.keys(this.roster_structure)){
      times(this.roster_structure[i])(() => this.myTeam.push({pos:i,player:null}));
    }
    //this.myTeam = [];
  }

  
  getQBs(){
    return this.qbs;
  }
  getRBs(){
    return this.rbs;
  }
  getWRs(){
    return this.wrs;
  }
  getTEs(){
    return this.tes;
  }
  getKs(){
    return this.ks;
  }
  getDSTs(){
    return this.dsts;
  }

  resetDraftOrder(user_pick:number){
    this.player_order = user_pick;
    this.generateDraftOrder();
  }

  resetPlayerOrder(user_pick:number){
    this.player_order = user_pick;
  }

  resetContestants(contestants){
    this.no_contestants = contestants;
  }

  getPickPointer(){
    return this.pick_pointer;
  }

  initPossible(){
    this.possible_order = [];
    for(let i = 1; i <= this.no_contestants;i++){
      this.possible_order.push(i);
    }
  }

  getPossible(){
    return this.possible_order;
  }

  generateDraftOrder(){
    this.draft_order = [];
    this.pick_pointer = 0;
    let no_rounds = this.getRounds();
    let odd_rounds = no_rounds % 2;
    if(odd_rounds){
      no_rounds -= 1;
    }
    for(let i = 0; i < (no_rounds/2); i++){
      for(let j = 0; j < this.no_contestants; j++){
        if(j == this.player_order-1){
          this.draft_order.push('My Team');
        }
        else{
          this.draft_order.push('bot');
        }
      }
      for(let p = this.no_contestants-1; p >= 0; p--){
        if(p == this.player_order-1){
          this.draft_order.push('My Team');
        }
        else{
          this.draft_order.push('bot');
        }
      }
    }
    if(odd_rounds){
      for(let t = 0; t < this.no_contestants;t++){
        if(t == this.player_order -1){
          this.draft_order.push('My Team');
        }
        else{
          this.draft_order.push('bot');
        }
      }
    }
  }

  getRounds(){
    let pos = this.getPositions();
    let rounds = 0;
    for(let p of pos){
      rounds += p;
    }
    return rounds;
  }

  getNoContestants(){
    return this.no_contestants;
  }

  getOrder(){
    return this.player_order;
  }

  setOrder(order:number){
    this.player_order = order;
  }

  setNumberOfContestants(contestans:number){
    this.no_contestants = contestans;
    this.initPossible();
    if(this.player_order > contestans){
      this.resetDraftOrder(1);
    }
    
  }

  setDraftOrder(draft_order:any){
    this.draft_order = draft_order;
  }
  
  isPick(){
    if(this.draft_order[this.pick_pointer] == 'My Team'){
      return true;
    }
    else{
      return false;
    }
  }


  getPicksUntil(){
    return this.draft_order.slice(this.pick_pointer).indexOf('My Team');
  }
  openFronSettings(){
    this.front_page_open = true;
  }
  closeFrontSettings(){
    this.front_page_open = false;
  }

  getFrontOpen(){
    return this.front_page_open;
  }

  getDraftType(){
    return this.draft_type;
  }
  setDraftType(dtype:string){
    this.draft_type = dtype;
  }

  setPosNumber(pos:string,no:number){
    this.roster_structure[pos] = no;
    this.initMyTeam();
  }
  getPosNumber(pos:string){
    return this.roster_structure[pos];
  }
}
