import { Component, OnInit } from '@angular/core';
import {LeagueService} from '../_services/league.service';

@Component({
  selector: 'app-league',
  templateUrl: './league.component.html',
  styleUrls: ['./league.component.css']
})
export class LeagueComponent implements OnInit {

  constructor(private ls:LeagueService) { }

  
  

  ngOnInit() {
    this.ls.generatePlayers();
  }

  gL(){
    return this.ls.getLeauge();
  }

  getLeagueInfo(){
    return this.ls.getLeaugeInfo();
  }
  shuffle(){
    this.ls.shuff();
  }

  getUserDraftOrder(){
    return this.ls.getUserDraftOrder();
  }

  startDraft(){
    this.ls.startDraft();
  }

}
